var autocompleteInit = (function(){

    $(function(){
        var ac = autocompleteSettings;
        ac.container.autocomplete({
            source: function( request, response ) {
                $.ajax({
                    headers: {
                        'X-CSRF-Token': $('meta[name="_token"]').attr('content')
                    },
                    url :ac.url,
                    data: { term: request.term },
                    method: "POST",
                    dataType: "json"
                })
                    .done(function(data, textStatus, jqXHR){
                        if(typeof ac.successCallback !== 'undefined'){
                            ac.successCallback(data, textStatus, jqXHR);
                        }
                        response(data);
                    })
                    .fail(function(jqXHR, textStatus, errorThrown){
                        if(typeof ac.failCallback !== 'undefined'){
                            ac.failCallback(jqXHR, textStatus, errorThrown);
                        }
                    });
            },
            minLength: 3,
            select: function(e, ui){
                if(typeof ac.selectCallback !== 'undefined'){
                    ac.selectCallback(e, ui);
                }
            },
            response: function(e, ui){
                if(typeof ac.responseCallback !== 'undefined'){
                    ac.responseCallback(e, ui);
                }
            },
            open: function(){
                if(typeof ac.openCallback !== 'undefined'){
                    ac.openCallback();
                }
            }
        });

    });

});